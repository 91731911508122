import axios from 'axiosInstance'
import Vue from 'vue'
import Routes from '@lirec/routesjs-epopia/dist/index.min.js'

// initial state
const state = {
  all: {}
}

// getters
const getters = {
  credits: (state) => {
    return Object.values(state.all)
  },
  taskOrMonthCredits: (state, getters) => getters.credits.filter((credit) => ['task', 'month'].includes(credit.item_type)),
  storybookCredits: (state, getters) => getters.credits.filter((credit) => ['story_book'].includes(credit.item_type)),
  // Credits for tasks/month
  postalAndDiyCredits: (state, getters) => getters.taskOrMonthCredits.filter((credit) => credit.item_task_type.category !== 'digital_only'),
  digitalOnlyCredits: (state, getters) => getters.taskOrMonthCredits.filter((credit) => credit.item_task_type.category === 'digital_only'),
  frenchSchoolCredits: (state, getters) => getters.credits.filter((credit) => credit.item_recipient_type === 'french_school'),
  numberOfRemainingPostalTaskCredits: (state, getters) => getters.postalAndDiyCredits.reduce((acc, currentCredit) => {
    return currentCredit.item_type === 'task' ? (acc + currentCredit.item_quantity_remaining) : acc
  }, 0),
  numberOfRemainingPostalMonthCredits: (state, getters) => getters.postalAndDiyCredits.reduce((acc, currentCredit) => {
    return currentCredit.item_type === 'month' ? (acc + currentCredit.item_quantity_remaining) : acc
  }, 0),
  numberOfRemainingDigitalOnlyTaskCredits: (state, getters) => getters.digitalOnlyCredits.reduce((acc, currentCredit) => {
    return currentCredit.item_type === 'task' ? (acc + currentCredit.item_quantity_remaining) : acc
  }, 0),
  numberOfRemainingDigitalOnlyMonthCredits: (state, getters) => getters.digitalOnlyCredits.reduce((acc, currentCredit) => {
    return currentCredit.item_type === 'month' ? (acc + currentCredit.item_quantity_remaining) : acc
  }, 0)
}

// actions
const actions = {
  loadCredits ({ commit }) {
    return axios.get(Routes.api_epopia_credits_url())
      .then(response => {
        const credits = response.data
        credits.forEach((book) => commit('setCredit', book))
        return credits
      })
  },

  loadCredit ({ commit }, bookId) {
    return axios.get(Routes.api_epopia_book_url(bookId))
      .then((response) => {
        commit('setCredit', response.data)
        return response.data
      })
  }
}

// mutations
const mutations = {
  setCredit (state, book) {
    Vue.set(state.all, book.id, book)
  },
  // Only for development
  deleteCredit (state, creditId) {
    Vue.delete(state.all, creditId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
