<template>
  <v-snackbar
    :value="showSnackbar"
    :color="snackbarType"
    @input="(value) => hideSnackbar()"
  >
    {{ snackbarText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        {{ $t('common.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    showSnackbar () {
      return this.$store.getters['ui/showSnackbar']
    },
    snackbarText () {
      return this.$store.getters['ui/snackbarText']
    },
    snackbarType () {
      return this.$store.getters['ui/snackbarType']
    }
  },
  methods: {
    hideSnackbar () {
      this.$store.commit('ui/hideSnackbar')
    }
  }
}
</script>

<style>

</style> 