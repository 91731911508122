import axios from 'axiosInstance'
import Vue from 'vue'
import Routes from '@lirec/routesjs-epopia/dist/index.min.js'

// initial state
const state = {
  all: {},
  currentStoryId: null
}

// getters
const getters = {
  stories: state => state.all,
  storyById: state => id => state.all[id],
  currentStory: (state) => state.all[state.currentStoryId] || null,
  postalStories: (state, getters) => {
    return Object.values(getters.stories).filter((story) => !story.digital_only)
  },
  digitalOnlyStories: (state, getters) => {
    return Object.values(getters.stories).filter((story) => story.digital_only)
  }
}

// actions
const actions = {
  // Fetch all stories from a user
  loadAllStories ({ commit }) {
    return axios.get(Routes.api_epopia_stories_url())
      .then(response => response.data)
      .then(stories => {
        stories.forEach(story => commit('setStory', story))
      })
  },
  loadStory ({ commit, dispatch, state }, storyId) {
    return axios.get(Routes.api_epopia_story_url(storyId))
      .then((response) => {
        commit('setStory', response.data)
      })
  },
  loadStoryRelations ({ commit, dispatch, state }, storyId) {
    return axios.get(Routes.api_epopia_user_story_relations_url(storyId))
      .then((response) => {
        return response
      })
  },
  deleteStoryRelation ({ commit, state }, storyId) {
    return axios.delete(Routes.api_epopia_user_story_relation_url(storyId))
      .then((response) => {
        return response
      })
  },
  createStory ({ commit }, { universeId, recipientType, taskTypeCategory, creationReference = null, firstname = null, lastname = null, closurePos = null }) {
    return axios.post(Routes.api_epopia_stories_url(), { universe_id: universeId, recipient_type: recipientType, closure_requested_for_pos: closurePos, task_type_category: taskTypeCategory, firstname: firstname, lastname: lastname, creation_reference: creationReference })
      .then(response => response.data)
      .then((story) => {
        commit('setStory', story)
        return story
      })
  },
  updateStory ({ commit, dispatch }, { storyId, attrs }) {
    return axios.patch(Routes.api_epopia_story_url(storyId), attrs)
      .then((response) => {
        const story = response.data
        commit('setStory', story)
        return story
      })
  },

  setStartDate ({ commit }, { storyId, startDate }) {
    return axios.patch(Routes.set_start_date_api_epopia_story_url(storyId), { date: startDate })
      .then(response => response.data)
      .then((story) => {
        commit('setStory', story)
        return story
      })
  },

  pauseStory ({ commit, state }, { storyId, unpauseDate }) {
    const data = {
      ...(unpauseDate && { date: unpauseDate })
    }
    return axios.patch(Routes.pause_api_epopia_story_url(storyId), data)
      .then(response => response.data)
      .then((story) => {
        commit('setStory', story)
      })
  },

  setUnpauseDate ({ commit, state }, { storyId, date }) {
    return axios.patch(Routes.set_unpause_date_api_epopia_story_url(storyId), { date: date })
      .then(response => response.data)
      .then((story) => {
        commit('setStory', story)
      })
  },

  activateStory ({ commit, state }, storyId) {
    return axios.patch(Routes.restart_api_epopia_story_url(storyId))
      .then(response => response.data)
      .then((story) => {
        commit('setStory', story)
      })
  },

  deleteStory ({ commit, state }, storyId) {
    return axios.delete(Routes.api_epopia_story_url(storyId))
      .then(response => response.data)
      .then((story) => {
        commit('deleteStory', storyId)
      })
  },

  requestClosure ({ commit, state }, { storyId, taskPosition  = null }) {
    return axios.patch(Routes.request_closure_api_epopia_story_url(storyId), { pos: taskPosition })
      .then(response => response.data)
      .then((story) => {
        commit('setStory', story)
        return story
      })
  },

  toggleNextStoryCreationRefusal ({ commit, state }, storyId) {
    return axios.patch(Routes.toggle_next_story_creation_refused_api_epopia_story_url(storyId))
      .then(response => response.data)
      .then((story) => {
        commit('setStory', story)
      })
  },

  changeNextStoryUniverse ({ commit, state }, { storyId, universeId }) {
    const data = {
      universe_id: universeId
    }
    return axios.patch(Routes.change_next_story_universe_api_epopia_story_url(storyId), data)
      .then(response => response.data)
      .then((story) => {
        commit('setStory', story)
      })
  },

  setCurrentStory ({ commit }, story) {
    commit('setCurrentStoryId', story.id)
    commit('setStory', story)
  },

  storiesSuggestedForPause ({ commit, state }, storyId) {
    return axios.get(Routes.stories_for_suggested_pause_api_epopia_story_url(storyId))
      .then(response => response.data)
  },

  identicalStories ({ commit, state }, storyId) {
    return axios.get(Routes.identical_stories_api_epopia_story_url(storyId))
      .then(response => response.data)
  }
}

// mutations
const mutations = {
  setStory (state, story) {
    Vue.set(state.all, story.id, story)
  },
  setCurrentStory (state, story) {
    state.currentStory = story
  },
  setCurrentStoryId (state, storyId) {
    state.currentStoryId = storyId
  },
  setStoryCurrentTask (state, task) {
    Vue.set(state.all[task.story_id], 'current_task', task)
  },
  deleteStory (state, storyId) {
    Vue.delete(state.all, storyId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
